import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AmberScore from "../components/reuseable/AmberScore";

const Legal = () => (
  <Layout>
    <SEO title="Amber by inFeedo | Legal" />
    <div class="content has-text-centered columns flex-wrap mar-top-160">
            <h2 class="text-block-17 column is-12 is-paddingless">Disclaimer</h2>
            <h4 class="has-text-weight-normal column is-12 is-paddingless">We don't mean any harm</h4>
            <div className="font-15 pd-40 column is-9 mar-auto has-text-justified">
                The information contained on this website <b>(www.infeedo.com)</b> is for general information purposes only. 
                The information is provided by <b>inFeedo Private Limited</b> (“inFeedo”/ “we”). 
                and while we endeavour to keep the information up to date and correct, 
                we make no representations or warranties of any kind, express or implied, 
                about the completeness, accuracy, reliability, suitability or availability with respect to the website or the information, 
                products, services, or related graphics contained on the website for any purpose. 
                Any reliance you place on such information is therefore strictly at your own risk.
                <br/><br/>
                In no event will we at inFeedo be liable for any loss or damage including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection with, the use of this website.
                <br/><br/>
                Through this website you are able to link to other websites which are not under the control of inFeedo. We have no control over the nature, content and availability of those sites. The inclusion of any links does not necessarily imply a recommendation or endorse the views expressed within them by inFeedo.
                <br/><br/>
                Every effort is made to keep the website up and running smoothly. However, inFeedo takes no responsibility for, and will not be liable for, the website being temporarily unavailable due to technical issues beyond our control.
            </div>
    </div>
    <AmberScore/>
  </Layout>
)

export default Legal

