import React from "react";
import ScheduleDemoButton from '../scheduledemo/ScheduleDemoButton';
import G2_crowd_img from '../../images/MedalG2draft.svg'
import amber_img from '../../images/Asset1@2x.png'

const AmberScore = () => (
    <div className="has-text-centered bg-amberscore padding-20-mobile">
        <div className="pd-10">
            <div className="is-hidden-tablet">
                <br/>
                <br/>
            </div>
            <h3 className="is-size-3-tablet is-size-5-mobile has-text-weight-bold pd-bottom-40">Our customers and their employees love Amber!</h3>
            <div className="g2-crowd-div pd-bottom-40">
                <div className="g2-crowd-g2ScoreDiv">
                    <img className="g2-crowd-img" src={G2_crowd_img}  alt="infeedo logo white"></img>
                    <h1 className="has-text-weight-bold is-size-7-mobile"> G2Crowd Ranked Top 5 Employee <br></br> Engagement Solution in APAC</h1>
                </div>
                <div>
                    <img  className="g2-crowd-amber-img" src={amber_img}  alt="infeedo logo white"></img>
                    <h1 className="has-text-weight-bold is-size-7-mobile">Employees have scored<br></br> her 4.46/5</h1>
                </div>
            </div>
            <p className="is-size-4 is-size-6-mobile">We are certain you will too.</p>
            <br/><br/>
            <div className="is-hidden-mobile centered-input"><ScheduleDemoButton initial_email_enabled={true}/></div>
            <div className="is-hidden-tablet"><ScheduleDemoButton/></div>
            <div className="is-hidden-tablet">
                <br/>
                <br/>
            </div>
        </div>
    </div>
)

export default AmberScore

